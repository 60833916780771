export function PageNotFound() {
  return (
    <main className="mb-auto">
      <div>
        <div className="text-center mt-4">
          <h1>404</h1>
          <h4>Page not found</h4>
        </div>
      </div>
    </main>
  )
}

import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

export function PrivacyPolicy() {
  const emailAddress = 'sh.work100@gmail.com';

  return (
    <Container className="mt-5">
      <h1 className="text-center">Privacy Policy</h1>
      <p className="text-center">Last Updated: 01. September 2023</p>

      <Row>
        <Col md={{ span: 8, offset: 2 }}>
          <p>
            This Privacy Policy describes how BUDGET TICKERS collects,
            uses, and shares personal information when you visit and use our website www.budgettickers.com.
          </p>
          <p>
            By using the Website, you agree to the terms outlined in this Privacy Policy. If you do not agree with these
            terms, please do not use the Website.
          </p>
          <h2>Information We Collect</h2>
          <p>
            <strong>Personal Information:</strong> We may collect personal information, such as your name, email address,
            postal address, phone number, and other relevant information, when you voluntarily provide it to us through
            forms on the Website or through other means of communication.
          </p>
          <p>
            <strong>Usage Information:</strong> We may collect information about how you use the Website, such as your IP
            address, browser type, operating system, referring URLs, access times, and pages viewed.
          </p>
          <h2>How We Use Your Information</h2>
          <p>
            We may use the collected information for various purposes, including but not limited to:
          </p>
          <ul>
            <li>Providing, maintaining, and improving the Website.</li>
            <li>Responding to your inquiries and providing customer support.</li>
            <li>Sending you updates, newsletters, and promotional materials.</li>
            <li>Analyzing usage patterns to improve user experience.</li>
            <li>Preventing fraudulent activity and ensuring the security of our users.</li>
          </ul>
          <h2>Cookies and Tracking Technologies</h2>
          <p>
            We may use cookies, web beacons, and other tracking technologies to collect information about your browsing
            activities on the Website. You can manage your cookie preferences through your browser settings.
          </p>
          <h2>Third-Party Links and Services</h2>
          <p>
            The Website may contain links to third-party websites, services, and content that are not operated or controlled
            by us. We are not responsible for the privacy practices of these third parties. We encourage you to review their
            privacy policies before providing any personal information.
          </p>
          <h2>Data Sharing and Disclosure</h2>
          <p>
            We may share your personal information in the following circumstances:
          </p>
          <ul>
            <li>With service providers and partners who assist us in operating the Website and delivering our services.</li>
            <li>In response to legal requests, such as court orders or subpoenas.</li>
            <li>To protect our rights, privacy, safety, or property, as well as that of our users and the public.</li>
            <li>In connection with a business transaction, such as a merger, sale, or asset transfer.</li>
          </ul>
          <h2>Your Choices</h2>
          <p>
            You have the right to:
          </p>
          <ul>
            <li>Access, correct, or update your personal information.</li>
            <li>Opt-out of receiving promotional emails and newsletters.</li>
            <li>Delete your account and personal information.</li>
          </ul>
          <h2>Security</h2>
          <p>
            We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration,
            or destruction.
          </p>
          <h2>Changes to this Privacy Policy</h2>
          <p>
            We may update this Privacy Policy from time to time to reflect changes to our practices or for legal reasons. The
            updated policy will be posted on this page.
          </p>
          <h2>Contact Us</h2>
          <p>
            If you have any questions or concerns about this Privacy Policy, please contact us at
            <a href={`mailto:${emailAddress}`} className='m-0 mx-2'>{emailAddress}</a>.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

import { Container, Row, Col, Image } from 'react-bootstrap';

export function About() {
  return (
    <main className='mb-auto'>
      <Container>

        {/* Title */}
        <Row className="mb-4 mt-3">
          <Col>
            <h1>About Budget Tickers</h1>
          </Col>
        </Row>

        {/* Introduction */}
        <Row className="mb-4">
          <Col>
            <h2>Introduction</h2>
            <p>At Budget Tickers, we share a profound love for watches. Understanding that not everyone wishes to invest in
              luxury timepieces, we've set out to discover and showcase watches that are both affordable and of high quality.
              Here, you'll find reviews, insights, and stories from the world of budget watches.
            </p>
          </Col>
        </Row>

        {/* Mission */}
        <Row className="mb-4">
          <Col>
            <h2>Our Mission</h2>
            <p>Our mission at Budget Tickers is simple: to help watch enthusiasts find the perfect timepiece that aligns with their
              budget and style. We believe that every wrist deserves a watch that tells more than just time – it should tell a story,
              embody character, and most importantly, be accessible. We're here to guide you through the vast world of
              budget-friendly watches, ensuring you get the best value for your money.
            </p>
          </Col>
        </Row>

        {/* Author Bio */}
        <Row className="mb-4 align-items-center">
          <Col xs={6} md={4} lg={2} className="mx-auto mx-md-0 mb-3 mb-md-0">
            <Image src="SimonAvatar.png" alt="Author Profile Image" roundedCircle fluid className="d-block mx-auto mx-md-0" />
          </Col>
          <Col xs={12} md={8} className="mt-0 mt-sm-3">
            <h2>About the Author</h2>
            <p>Welcome to Budget Tickers! My name is Simon and I've been an ardent admirer of watches for as long as I can remember.
              The craft, history, and mechanical prowess behind each timepiece fascinate me. Thank you for visiting, and I hope
              my findings and reviews guide you to your next beloved watch.
            </p>
          </Col>
        </Row>

      </Container>
    </main>
  );
}

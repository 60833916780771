import React from 'react';
import { BlogNavbar, Footer } from './components/index';
import AllRoutes from './routes/AllRoutes';
import { Analytics } from '@vercel/analytics/react';
import './App.css';

export function App() {
  return (
    <div className="App d-flex flex-column min-vh-100 bg-dark text-white">
      <div className="main-content">
        <BlogNavbar />
        <div className="flex-grow-1">
          <AllRoutes />
        </div>
        <Footer />
      </div>
      <Analytics />
    </div>
  );
}

// Pagination.js
import React from 'react';
import { Button } from 'react-bootstrap';

export const Pagination = ({ totalItems, itemsPerPage, currentPage, onPageChange }) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  return (
    <div className="pagination-controls text-center">
      <Button
        variant="btn btn-outline-light mx-2"
        onClick={() => onPageChange(Math.max(currentPage - 1, 1))}
        disabled={currentPage === 1}>
        Prev
      </Button>
      <span className="mx-3">Page {currentPage} of {totalPages}</span>
      <Button
        variant="btn btn-outline-light mx-2"
        onClick={() => onPageChange(Math.min(currentPage + 1, totalPages))}
        disabled={currentPage === totalPages}>
        Next
      </Button>
    </div>
  );
}

import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './Footer.css';

export function Footer() {
  return (
    <main>
      <footer className="mt-5 pt-3 footer-gradient">
        <Container className="container-footer-border">
          <Row>
            <Col md={4} className="mb-3">
              <h5>About</h5>
              <p>
                Discovering timeless elegance without the luxury price.
              </p>
              <Link to="/privacy-policy" className='m-0 footer-link'>Privacy Policy</Link>
              <Link to="/terms-conditions" className='footer-link'>Terms & Conditions</Link>
            </Col>
            <Col md={4} className="mb-3">
              <h5>Contact</h5>
              <p>
                sh.work100@gmail.com
              </p>
            </Col>
            <Col md={4} className="mb-3">
              <h5>Copyright</h5>
              <p>
                &copy; {new Date().getFullYear()} BUDGET TICKERS. All rights reserved.
              </p>
            </Col>
          </Row>
        </Container>
      </footer>
    </main>
  );
}

import React from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { NavLink } from 'react-router-dom';
import './BlogNavbar.css';

export function BlogNavbar() {
  return (
    <main className="mb-auto">
      <Navbar expand="lg" className="navbar-dark bg-dark">
        <Container>
          <Navbar.Brand to="/">BUDGET TICKERS</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <NavLink exact="true" to="/" className={(navData) => (navData.isActive ? "active-style" : 'none')}>Home</NavLink>
              <NavLink to="/watch-fundamentals" className={(navData) => (navData.isActive ? "active-style" : 'none')}>Watch Fundamentals</NavLink>
              <NavLink to="/brand-reviews" className={(navData) => (navData.isActive ? "active-style" : 'none')}>Brand Reviews</NavLink>
              <NavLink to="/buying-guides" className={(navData) => (navData.isActive ? "active-style" : 'none')}>Buying Guides</NavLink>
              <NavLink to="/style-and-customization" className={(navData) => (navData.isActive ? "active-style" : 'none')}>Style and Customization</NavLink>
              <NavLink to="/about" className={(navData) => (navData.isActive ? "active-style" : 'none')}>About</NavLink>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </main>
  );
}

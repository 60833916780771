import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, Container, Row, Col } from 'react-bootstrap';
import { ArticlesData } from '../components/ArticlesData';
import { Pagination } from '../components/Pagination';
import { isMobile } from 'react-device-detect';
import './Home.css';

export function Home() {
  const [currentPage, setCurrentPage] = useState(1);
  const articlesPerPage = isMobile ? 8 : 15;

  // Flattening ArticlesData to a single array of articles for easier pagination
  const allArticles = ArticlesData.flatMap(category => category.articles);
  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = allArticles.slice(indexOfFirstArticle, indexOfLastArticle);

  // Adjusted paginate function to include the scrollTo method with a delay
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    setTimeout(() => {  // <-- MARKED CHANGE: added delay
      window.scrollTo(0, 0);
    }, 100);  // <-- delay of 100ms, adjust if necessary
  };

  return (
    <main className="mb-auto">
      <Container className="py-4">
        <div className="text-center">
          <h1>Welcome to Budget Tickers</h1>
          <p className="mb-5">
            Welcome to <strong>Budget Tickers</strong> - your ultimate destination for discovering affordable timepieces without compromising on quality. In a world dominated by luxury watches, we believe that style, elegance, and functionality shouldn't break the bank. Dive into our curated collection of budget watches and let every tick take your breath away without taking much from your wallet.
          </p>
        </div>
        <Row xs={1} md={2} lg={3} className="g-4">
          {currentArticles.map((article, index) => (
            <Col key={index} className="d-flex">
              <Link to={article.link} className="text-decoration-none">
                <Card className="h-100 card-background text-white custom-hover">
                  <Card.Img
                    className='rounded'
                    variant="top"
                    src={article.imageSrc}
                    alt={article.title}
                    style={{
                      objectFit: 'cover',
                      height: '200px',
                      width: '100%'
                    }}
                  />
                  <Card.Body className="d-flex flex-column">
                    <Card.Title>{article.title}</Card.Title>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
          ))}
        </Row>
        <div className="mt-4">
          <Pagination
            itemsPerPage={articlesPerPage}
            totalItems={allArticles.length}
            onPageChange={paginate}
            currentPage={currentPage}
          />
        </div>
      </Container>
    </main>
  );
}

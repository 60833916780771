import { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import {
  Home, About,
  WatchFundamentals, BrandReviews, BuyingGuides, StyleAndCustomization,
  PageNotFound, PrivacyPolicy, TermsConditions, Impressum
} from '../pages';

import {
  Article1, Article2, Article3, Article4, Article5, Article6, Article7, Article8, Article9, Article10,
  Article11, Article12, Article13, Article14, Article15, Article16, Article17, Article18, Article19, Article20,
  Article21, Article22, Article23, Article24, Article25, Article26, Article27, Article28, Article29, Article30,
  Article31, Article32, Article33, Article34, Article35, Article36, Article37, Article38, Article39, Article40
} from '../articles';

// Scroll to top of page on route change
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const AllRoutes = () => {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/watch-fundamentals' element={<WatchFundamentals />} />
        <Route path='/brand-reviews' element={<BrandReviews />} />
        <Route path='/buying-guides' element={<BuyingGuides />} />
        <Route path='/style-and-customization' element={<StyleAndCustomization />} />
        <Route path='/about' element={<About />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='/terms-conditions' element={<TermsConditions />} />
        <Route path='/impressum' element={<Impressum />} />
        {/* Relative paths for articles within the Blog component */}
        <Route path='how-do-mechanical-watches-work' element={<Article1 />} />
        <Route path='review-seiko-5-series-mechanical-watches' element={<Article2 />} />
        <Route path='how-to-choose-the-perfect-mechanical-watch-for-under-200' element={<Article3 />} />
        <Route path='customizing-your-mechanical-watch-a-style-guide-for-the-budget-conscious' element={<Article4 />} />
        <Route path='winding-a-mechanical-watch' element={<Article5 />} />
        <Route path='orient-watches-unbeatable-value' element={<Article6 />} />
        <Route path='where-to-buy-affordable-mechanical-watches-online' element={<Article7 />} />
        <Route path='the-best-watch-straps-for-your-budget-mechanical-watch' element={<Article8 />} />
        <Route path='mechanical-watch-accuracy' element={<Article9 />} />
        <Route path='invicta-watches-style-vs-substance' element={<Article10 />} />
        <Route path='mechanical-watches-for-different-occasions-formal-casual-and-sport' element={<Article11 />} />
        <Route path='how-to-change-the-watch-face-and-hands' element={<Article12 />} />
        <Route path='mechanical-watch-power-reserve' element={<Article13 />} />
        <Route path='timex-mechanical-watches-a-hidden-gem' element={<Article14 />} />
        <Route path='how-to-understand-watch-specifications-and-terms' element={<Article15 />} />
        <Route path='diy-customization-tips-and-tricks-for-mechanical-watches' element={<Article16 />} />
        <Route path='types-of-mechanical-watch-movements' element={<Article17 />} />
        <Route path='citizen-mechanical-watches-japanese-precision-on-a-budget' element={<Article18 />} />
        <Route path='features-to-look-for-in-a-budget-mechanical-watch' element={<Article19 />} />
        <Route path='matching-your-mechanical-watch-with-your-wardrobe' element={<Article20 />} />
        <Route path='mechanical-watch-basics' element={<Article21 />} />
        <Route path='the-affordable-swiss-watches-tissot-and-swatch' element={<Article22 />} />
        <Route path='the-best-mechanical-watches-for-women-under-200' element={<Article23 />} />
        <Route path='the-impact-of-watch-size-and-proportions-on-style' element={<Article24 />} />
        <Route path='mechanical-watch-complications' element={<Article25 />} />
        <Route path='vostok-watches-the-best-mechanical-watches-from-russia' element={<Article26 />} />
        <Route path='how-to-spot-and-avoid-fake-mechanical-watches' element={<Article27 />} />
        <Route path='watch-complications-style-or-functionality' element={<Article28 />} />
        <Route path='mechanical-watch-anatomy' element={<Article29 />} />
        <Route path='sthrling-original-watches-are-they-worth-the-money' element={<Article30 />} />
        <Route path='vintage-mechanical-watches-what-to-look-for' element={<Article31 />} />
        <Route path='how-to-customize-your-watch-dial' element={<Article32 />} />
        <Route path='history-of-mechanical-watches' element={<Article33 />} />
        <Route path='fossil-mechanical-watches-style-meets-affordability' element={<Article34 />} />
        <Route path='why-water-resistance-matters-in-mechanical-watches' element={<Article35 />} />
        <Route path='coordinating-your-watch-with-other-accessories' element={<Article36 />} />
        <Route path='mechanical-watch-accuracy-standards' element={<Article37 />} />
        <Route path='brand-showdown-seiko-vs-orient' element={<Article38 />} />
        <Route path='the-role-of-luminosity-in-budget-mechanical-watches' element={<Article39 />} />
        <Route path='seasonal-watch-styles-summer-vs-winter' element={<Article40 />} />
        <Route path='*' element={<PageNotFound />} />
      </Routes>
    </>
  )
};

export default AllRoutes;

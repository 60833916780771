import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Container, Row, Col } from 'react-bootstrap';
import { ArticlesData } from '../components/ArticlesData';
import './Home.css';

export function StyleAndCustomization() {
  const targetCategory = 'Style and Customization'; // Set the target category based on the page

  // Filter articles based on the target category
  const filteredCategory = ArticlesData.find(category => category.name === targetCategory);
  const filteredArticles = filteredCategory ? filteredCategory.articles : [];

  return (
    <main className="mb-auto">
      <Container className="py-4">
        <div className="text-center my-4">
          <h1>{targetCategory}</h1>
        </div>
        <Row xs={1} md={2} lg={3} className="g-4">
          {filteredArticles.map((article, index) => (
            <Col key={index} className="d-flex">
              <Link to={article.link} className="text-decoration-none">
                <Card className="h-100 card-background text-white custom-hover">
                  <Card.Img
                    className='rounded'
                    variant="top"
                    src={article.imageSrc}
                    alt={article.title}
                    style={{
                      objectFit: 'cover',
                      height: '200px',
                      width: '100%'
                    }}
                  />
                  <Card.Body className="d-flex flex-column">
                    <Card.Title>{article.title}</Card.Title>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
          ))}
        </Row>
      </Container>
    </main>
  );
}

// src/data/ArticlesData.js
export const ArticlesData = [
  {
    name: 'Watch Fundamentals',
    articles: [
      {
        title: 'The Art of Precision: Delving into How Mechanical Watches Work',
        imageSrc: '/images/mechanical_watch.jpg',
        text: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        link: '/how-do-mechanical-watches-work',
        topic: "WatchFundamentals"
      },
      {
        title: 'A Time-Turning Ritual: Exploring the Process of Winding a Mechanical Watch',
        imageSrc: '/images/mechanical_parts.jpg',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        link: '/winding-a-mechanical-watch',
        topic: "WatchFundamentals"
      },
      {
        title: 'Beyond Timekeeping: The Intricacies of Mechanical Watch Accuracy',
        imageSrc: '/images/quarz_watch.jpg',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        link: '/mechanical-watch-accuracy',
        topic: "WatchFundamentals"
      },
      {
        title: 'Unveiling the Magic: Discovering the Secrets of Mechanical Watch Power Reserve',
        imageSrc: '/images/wind_watch.jpg',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        link: '/mechanical-watch-power-reserve',
        topic: "WatchFundamentals"
      },
      {
        title: 'From Springs to Precision: Unveiling the Types of Mechanical Watch Movements',
        imageSrc: '/images/watch_maintenance.jpg',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        link: '/types-of-mechanical-watch-movements',
        topic: "WatchFundamentals"
      },
      {
        title: 'The Mechanics of Elegance: Unraveling the Basics of Mechanical Watches',
        imageSrc: '/images/automatic_watch.jpg',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        link: '/mechanical-watch-basics',
        topic: "WatchFundamentals"
      },
      {
        title: 'Embrace the Complexity: Discovering the Beauty of Mechanical Watch Complications',
        imageSrc: '/images/how_precize_watch.jpg',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        link: '/mechanical-watch-complications',
        topic: "WatchFundamentals"
      },
      {
        title: 'Mechanical Marvels: Decoding the Intricate Anatomy of Watches',
        imageSrc: '/images/watch_history.jpg',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        link: '/mechanical-watch-anatomy',
        topic: "WatchFundamentals"
      },
      {
        title: 'From Ancient Origins to Modern Marvels: History of Mechanical Watches Unveiled',
        imageSrc: '/images/swiss_watch.jpg',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        link: '/history-of-mechanical-watches',
        topic: "WatchFundamentals"
      },
      {
        title: 'The Art of Timekeeping: Exploring Mechanical Watch Accuracy Standards',
        imageSrc: '/images/watch_store.jpg',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        link: '/mechanical-watch-accuracy-standards',
        topic: "WatchFundamentals"
      },
    ],
  },
  {
    name: 'Brand Reviews and Comparisons',
    articles: [
      {
        title: 'The Affordable Luxury: A Close Look at Seiko 5 Series Mechanical Watches',
        imageSrc: '/images/seiko_watch.jpg',
        text: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        link: '/review-seiko-5-series-mechanical-watches',
        topic: "Brand Reviews and Comparisons"
      },
      {
        title: 'The Value Dilemma: Can Orient Watches Live Up to the Hype?',
        imageSrc: '/images/orient_watch.jpg',
        text: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        link: '/orient-watches-unbeatable-value',
        topic: "Brand Reviews and Comparisons"
      },
      {
        title: 'The Ultimate Showdown: Invicta Watches - Which Prevails, Style or Substance?',
        imageSrc: '/images/invicta_watch.jpg',
        text: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        link: '/invicta-watches-style-vs-substance',
        topic: "Brand Reviews and Comparisons"
      },
      {
        title: 'Hidden Delights: Timex Mechanical Watches and the Allure of a Budget Gem',
        imageSrc: '/images/timex_mechanical_watch.jpg',
        text: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        link: '/timex-mechanical-watches-a-hidden-gem',
        topic: "Brand Reviews and Comparisons"
      },
      {
        title: 'Unlock Japanese Excellence: Citizen Mechanical Watches for Savvy Shoppers',
        imageSrc: '/images/citizen_mechanical_watche.jpg',
        text: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        link: '/citizen-mechanical-watches-japanese-precision-on-a-budget',
        topic: "Brand Reviews and Comparisons"
      },
      {
        title: 'Style Meets Savings: Exploring Tissot and Swatch - The Affordable Swiss Watches',
        imageSrc: '/images/tissot_watch.jpg',
        text: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        link: '/the-affordable-swiss-watches-tissot-and-swatch',
        topic: "Brand Reviews and Comparisons"
      },
      {
        title: 'The Russian Watch Revolution: Vostoks Finest Mechanical Watches',
        imageSrc: '/images/vostok_watche.jpg',
        text: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        link: '/vostok-watches-the-best-mechanical-watches-from-russia',
        topic: "Brand Reviews and Comparisons"
      },
      {
        title: 'The Value Dilemma: Discovering Stührling Original Watches Worth',
        imageSrc: '/images/stuehrling_original_watche.jpg',
        text: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        link: '/sthrling-original-watches-are-they-worth-the-money',
        topic: "Brand Reviews and Comparisons"
      },
      {
        title: 'Fossil Mechanical Watches: Affordable Style for the Discerning Watch Lover',
        imageSrc: '/images/fossil_mechanical_watche.jpg',
        text: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        link: '/fossil-mechanical-watches-style-meets-affordability',
        topic: "Brand Reviews and Comparisons"
      },
      {
        title: 'Time for Battle: Seiko vs. Orient - The Ultimate Brand Showdown',
        imageSrc: '/images/orient1_watch.jpg',
        text: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        link: '/brand-showdown-seiko-vs-orient',
        topic: "Brand Reviews and Comparisons"
      },
    ],
  },
  {
    name: 'Buying Guides',
    articles: [
      {
        title: 'Unlocking Style without Breaking the Bank: How to Choose the Perfect Mechanical Watch for Under $200',
        imageSrc: '/images/watch1_store.jpg',
        text: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        link: '/how-to-choose-the-perfect-mechanical-watch-for-under-200',
        topic: "Buying Guides"
      },
      {
        title: 'Unleashing Affordable Luxury: Where to Buy Mechanical Watches Online for Less',
        imageSrc: '/images/watch_occasions.jpg',
        text: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        link: '/where-to-buy-affordable-mechanical-watches-online',
        topic: "Buying Guides"
      },
      {
        title: 'From Boardroom to Weekend Adventures: Mechanical Watches for All Occasions',
        imageSrc: '/images/watch_terms.jpg',
        text: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        link: '/mechanical-watches-for-different-occasions-formal-casual-and-sport',
        topic: "Buying Guides"
      },
      {
        title: 'Unlocking the Secrets: How to Master Watch Specifications and Terms',
        imageSrc: '/images/watch_features.jpg',
        text: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        link: '/how-to-understand-watch-specifications-and-terms',
        topic: "Buying Guides"
      },
      {
        title: 'Budget Brilliance: Unveiling the Must-Have Features of a Mechanical Watch',
        imageSrc: '/images/budget_watch.jpg',
        text: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        link: '/features-to-look-for-in-a-budget-mechanical-watch',
        topic: "Buying Guides"
      },
      {
        title: 'Under $200, Above Expectations: The Best Mechanical Watches for Women',
        imageSrc: '/images/warranty_watch.jpg',
        text: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        link: '/the-best-mechanical-watches-for-women-under-200',
        topic: "Buying Guides"
      },
      {
        title: 'Preserving Your Style: How to Spot and Steer Clear of Fake Mechanical Watches',
        imageSrc: '/images/fake_watch.jpg',
        text: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        link: '/how-to-spot-and-avoid-fake-mechanical-watches',
        topic: "Buying Guides"
      },
      {
        title: 'Investing in the Past: Understanding Vintage Mechanical Watches',
        imageSrc: '/images/vitage_watch.jpg',
        text: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        link: '/vintage-mechanical-watches-what-to-look-for',
        topic: "Buying Guides"
      },
      {
        title: 'Unlocking Durability: Exploring Why Water Resistance Matters in Mechanical Watches',
        imageSrc: '/images/water_watch.jpg',
        text: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        link: '/why-water-resistance-matters-in-mechanical-watches',
        topic: "Buying Guides"
      },
      {
        title: 'Maximizing Value: The Crucial Role of Luminosity in Budget Mechanical Watches',
        imageSrc: '/images/woman_watch.jpg',
        text: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        link: '/the-role-of-luminosity-in-budget-mechanical-watches',
        topic: "Buying Guides"
      },
    ],
  },
  {
    name: 'Style and Customization',
    articles: [
      {
        title: 'Budget-Savvy Style Secrets: A Customization Guide for Your Mechanical Watch',
        imageSrc: '/images/watch_customization.jpg',
        text: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
        link: '/customizing-your-mechanical-watch-a-style-guide-for-the-budget-conscious',
        topic: "Style and Customization"
      },
      {
        title: 'The Ultimate Style Hack: Best Watch Straps for Your Budget Mechanical Watch',
        imageSrc: '/images/watch_strap.jpg',
        text: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
        link: '/the-best-watch-straps-for-your-budget-mechanical-watch',
        topic: "Style and Customization"
      },
      {
        title: 'Morph Your Timepiece: A Guide to Changing Watch Face and Hands',
        imageSrc: '/images/change_watch_face.jpg',
        text: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
        link: '/how-to-change-the-watch-face-and-hands',
        topic: "Style and Customization"
      },
      {
        title: 'Personalize on a Budget: DIY Customization Tips for Your Mechanical Watch',
        imageSrc: '/images/diy_watch_customization.jpg',
        text: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
        link: '/diy-customization-tips-and-tricks-for-mechanical-watches',
        topic: "Style and Customization"
      },
      {
        title: 'Perfect Harmony: How to Match Your Mechanical Watch with Your Wardrobe',
        imageSrc: '/images/matching_watch_with_wardrobe.jpg',
        text: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
        link: '/matching-your-mechanical-watch-with-your-wardrobe',
        topic: "Style and Customization"
      },
      {
        title: 'Decoding the Stylish Equation: Watch Size and Proportions Impact on Style',
        imageSrc: '/images/watch_size_and_proportion.jpg',
        text: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
        link: '/the-impact-of-watch-size-and-proportions-on-style',
        topic: "Style and Customization"
      },
      {
        title: 'Watch Complications: Finding the Sweet Spot Between Style and Functionality',
        imageSrc: '/images/watch_style_vs_functionality.jpg',
        text: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
        link: '/watch-complications-style-or-functionality',
        topic: "Style and Customization"
      },
      {
        title: 'Dial Up Your Style: How to Customize Your Watch Dial for a Personal Touch',
        imageSrc: '/images/customize_watch_dial.jpg',
        text: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
        link: '/how-to-customize-your-watch-dial',
        topic: "Style and Customization"
      },
      {
        title: 'Syncing Fashion: Coordinating Your Watch with Other Stylish Accessories',
        imageSrc: '/images/watch_woman.jpg',
        text: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
        link: '/coordinating-your-watch-with-other-accessories',
        topic: "Style and Customization"
      },
      {
        title: 'Summer Heat and Winter Chill: Exploring Seasonal Watch Styles',
        imageSrc: '/images/summer_vs_winter_watch.jpg',
        text: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
        link: '/seasonal-watch-styles-summer-vs-winter',
        topic: "Style and Customization"
      },
    ],
  },
];
